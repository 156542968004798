@import "../../module.variables.scss";

.OpennedOrders {
    padding: 15px;
    border-radius: $borderRadius;
    background: $dark;

    .title {
        color: $white;
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .tabs {
        display: flex;
        justify-content: space-around;

        .item {
            flex: 1;
            width: 100%;

            .label {
                padding: 10px;
                border-bottom: 1px solid $grey;
            }

            &.up {
                background: rgba($color: $success, $alpha: 0.1);

                .label {
                    color: $success;
                    border-bottom-color: $success;
                }
            }

            &.down {
                background: rgba($color: $danger, $alpha: 0.1);

                .label {
                    color: $danger;
                    border-bottom-color: $danger;
                }
            }
        }
    }

    .Table {
        background: transparent !important;

        tbody {
            background: transparent !important;
        }

        table {
            // table-layout: fixed;

            td, th {
                padding: 10px;
            }
        }

        .Message {
            font-size: 0.9em;

            svg {
                height: 14px;
            }
        }

        .Button {
            height: 30px;
            padding: 0 15px;
        }

        .orderCard {
            .status,
            .profit {
                &.draw {
                    color: $info;
                }

                &.win {
                    color: $success;
                }

                &.lose {
                    color: $danger;
                }
            }

            .profit {
                &.win,
                &.draw {
                    &::before {
                        content: "+";
                    }
                }
            }

            .option {
                display: inline-flex;
                padding: 4px;
                border-radius: 2px;

                svg {
                    height: 10px;

                    * {
                        fill: $white;
                    }
                }

                &.higher {
                    background: $success;
                }

                &.lower {
                    background: $danger;
                }
            }

            &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .Footer {
            display: none;
        }
    }
}
