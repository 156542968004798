@import "../../module.variables.scss";

.Button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: $buttonHeight;
	padding: 0 25px;
	outline: none;
	user-select: none;
	cursor: pointer;
	border-radius: $borderRadius;
	background: none;
	font-size: 1em;
	font-weight: 500;
	outline: none;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;

	&.dark {
		@include btnDarkColor($dark);
	}

	&.danger {
		@include btnDarkColor($danger);
	}

	&.warning {
		@include btnDarkColor($warning);
	}

	&.success {
		@include btnDarkColor($success);
	}

	&.info {
		@include btnDarkColor($info);
	}

	&.grey {
		@include btnDarkColor($grey);
	}

	&.primary {
		@include btnDarkColor($primary);
	}

	&.dark-outline {
		@include btnOutlineColor($dark);
	}

	&.grey-outline {
		@include btnOutlineColor($grey);
	}

	&.warning-outline {
		@include btnOutlineColor($warning);
	}

	&.danger-outline {
		@include btnOutlineColor($danger);
	}

	&.success-outline {
		@include btnOutlineColor($success);
	}

	&.info-outline {
		@include btnOutlineColor($info);
	}

	&.primary-outline {
		@include btnOutlineColor($primary);
	}

	.iconLoading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba($color: $white, $alpha: 0);

		svg {
			height: 18px;

			circle {
				stroke: $white;
				fill: transparent !important;
			}
		}
	}

	span.label {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.loading {
		cursor: not-allowed;

		span.label {
			opacity: 0;
		}

		> svg {
			opacity: 0;
		}

		&:hover {
			box-shadow: none;
		}
	}
}

.Button_Wraper_Middle {
	display: flex;
	align-items: center;
	justify-content: center;
}
