@import "../../module.variables.scss";

.Sentiments {
    .profit {
        display: flex;
        color: $success;
        margin-bottom: 30px;

        .percent {
            font-size: 1.5em;
            margin-right: 10px;
            font-weight: 300;
        }

        .increase {
            font-size: 2.4em;
            font-weight: 600;
        }
    }

    .sentiments {
        margin-bottom: 20px;

        .label {
            color: $white;
            margin-bottom: 5px;
        }

        .percent {
            display: flex;
            border-radius: $borderRadius;
            overflow: hidden;

            > * {
                height: 6px;

                &.up {
                    background: $success;
                }

                &.down {
                    background: $danger;
                }
            }
        }

        .percentLabel {
            display: flex;
            justify-content: space-between;

            > * {
                font-size: 1em;

                &.up {
                    color: $success;
                }

                &.down {
                    color: $danger;
                }
            }
        }
    }

    .PieChart {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        > * {
            max-width: 150px;
        }
    }
}
