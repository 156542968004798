@import "../../../module.variables.scss";
@import './chart-trade';
@import './results-panel';

.TradePannel {
    padding: 15px;
    border-radius: $borderRadius;
    background: $dark;

    .title {
        color: $white;
        font-size: 1.1em;
    }
}

.Mobile {
    .TradePannel {
        padding: 0px;
        background: transparent;
    }
}
