@import '../../module.variables.scss';

.InputToggleSwitch {
    .label {
        margin-bottom: 5px;
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba($color: $grey, $alpha: 0.5);
    
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: rgba($color: $grey, $alpha: 0.5);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $success;
    border-color: $success;
}

input:focus + .slider {
    box-shadow: 0 0 1px $success;
}

input:checked + .slider:before {
    transform: translateX(26px);
    background: $white;
}

/* Rounded sliders */
.slider.round {
    border-radius: 30px;
}

.slider.round:before {
    border-radius: 50%;
}
