@import "../../module.variables";

.UserHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    position: sticky;
    top: 0;
    background: $offDark;
    z-index: 100;

    .profile {
        flex: 1;
        display: flex;
        align-items: center;

        img.avatar {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: fill;
        }

        > .info {
            padding: 0 15px;

            .name {
                color: $white;
                font-size: 1.2em;
            }

            .email {
                color: $grey;
            }
        }
    }

    .ctas {
        user-select: none;
        display: flex;
        align-items: center;

        .InputToggleSwitch {
            display: flex;

            .label {
                margin-bottom: 0;
                margin-right: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            border-radius: $borderRadius;
            outline: none;
            border: none;
            font-size: 1em;
            padding: 0 15px;
            margin-right: 20px;
            cursor: pointer;
            position: relative;

            &.quickDeposit {
                background: $success;
                font-weight: 600;
                color: $white;

                svg {
                    height: 20px;
                    margin-right: 10px;

                    * {
                        fill: $white;
                    }
                }

                &:hover {
                    box-shadow: 0px 0px 5px rgba($color: $success, $alpha: 1);
                }
            }

            &.action {
                background: transparent;
                color: $white;
                font-weight: 600;

                svg {
                    height: 20px;
                    margin-right: 10px;

                    * {
                        fill: $white;
                    }
                }

                .qty {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: $danger;
                    color: $white;
                    font-weight: 600;
                    font-size: 0.7em;
                    line-height: 20px;
                    position: absolute;
                    top: 10px;
                    left: 28px;
                }
            }

            &.dropdowns {
                background: rgba($color: $grey, $alpha: 0.5);
                color: $white;
                position: relative;

                .info {
                    display: flex;
                    text-align: left;
                    flex-direction: column;

                    .label {
                        font-size: 0.8em;
                    }

                    .amount {
                        font-weight: 600;
                    }
                }

                .toggleIcon {
                    margin-left: 20px;

                    svg {
                        height: 5px;
                        transform: translateY(-2px);

                        * {
                            fill: $white;
                        }
                    }
                }

                .options {
                    position: absolute;
                    top: 100%;
                    right: 0;
                    padding-top: 10px;
                    transform: scaleY(0);
                    transform-origin: 50% 0%;
                    transition: 0.2s ease;

                    .wraper {
                        background: $dark;
                        width: max-content;
                        border-radius: $borderRadius;
                        border: 1px solid $borderColor;

                        .item {
                            text-align: left;
                            padding: 10px 15px;

                            .type {
                                margin-bottom: 5px;
                                color: $grey;
                                font-size: 0.8em;
                            }

                            .amount {
                                font-size: 1.2em;
                                font-weight: 600;
                            }

                            &.active {
                                cursor: default;

                                .type,
                                .amount,
                                .name {
                                    color: $primary;
                                }

                                .amount {
                                    &::before {
                                        content: "• ";
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .options {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }
}
