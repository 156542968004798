@import "../../../module.variables.scss";

.UserList {
    .btnGroup{

        svg{
            width : 16px;
            fill: #fff;
        }

        .btn{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            cursor: pointer;
            &__edit:hover{
                svg{    
                    fill: $grey;
                }   
            }

            &__ban{
                svg{
                    width: 18px;
                }
                &:hover{
                    svg{
                        fill: $warning;
                    }   
                }
            }
        }
    }
    
}


