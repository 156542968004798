@import "../../../module.variables";

.TableFilterInputSelect {
    width: 100%;
    border-radius: $borderRadius;
    border: 1px solid $borderColor;
    min-width: 150px;

    .TableFilterInputSelect__control {
        border: none !important;
        box-shadow: none !important;
        height: 37px;
        background: transparent;
        color: $white;
    }

    .TableFilterInputSelect__placeholder {
        color: $grey;
    }

    .TableFilterInputSelect__indicator-separator {
        background: $grey;
    }

    .TableFilterInputSelect__indicator {
        svg {
            * {
                fill: $grey;
            }
        }
    }

    .TableFilterInputSelect__single-value {
        color: $white;
    }

    .TableFilterInputSelect__input {
        input {
            color: $white !important;
        }
    }

    .TableFilterInputSelect__menu {
        z-index: 10;
        
        .TableFilterInputSelect__menu-list {
            background: $offDark;

            .TableFilterInputSelect__option {
                cursor: pointer;
                background: $offDark;
                overflow-x: hidden;

                &:hover {
                    background: rgba($color: $primary, $alpha: 0.2);
                }
            }
        }
    }

    .TableFilterInputSelect__option--is-selected {
        background: $primary;
        cursor: default;

        &:hover {
            background: $primary;
        }
    }
}
