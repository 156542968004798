@import "../../../module.variables";

.InputSelect {
    width: 100%;

    .InputSelect__control {
        border: none !important;
        box-shadow: none !important;
        height: $inputHeight;
    }

    .InputSelect__option {
        cursor: pointer;

        &:hover {
            background: rgba($color: $primary, $alpha: 0.2);
        }
    }

    .InputSelect__option--is-selected {
        background: $primary;
        cursor: default;

        &:hover {
            background: $primary;
        }
    }
}
