@import "../../module.variables";

$navigatorWidth: 110px;

.UserWraper {
    padding: 0px 0px 0px $navigatorWidth;
}

.Navigator {
    position: fixed;
    top: 0;
    left: 0;
    width: $navigatorWidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    background: $dark;
    height: 100%;
    user-select: none;
    overflow-x: hidden;

    img.logo {
        width: 50px;
        margin: 25px auto;
    }

    .menu {
        flex: 1;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $grey;
            text-decoration: none;
            padding: 22px 25px 0;
            margin-bottom: 15px;

            svg {
                width: 22px;
                margin-bottom: 10px;

                * {
                    fill: $grey;
                }
            }

            &.active {
                color: $primary;
                cursor: default;

                svg * {
                    fill: $primary;
                }

                &:hover {
                    color: $primary;

                    svg * {
                        fill: $primary;
                    }
                }
            }

            &:hover {
                color: $white;

                svg * {
                    fill: $white;
                }
            }
        }
    }

    .btnLogout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $grey;
        text-decoration: none;
        padding: 25px 0;
        cursor: pointer;

        svg {
            width: 22px;
            margin-bottom: 10px;

            * {
                fill: $grey;
            }
        }

        &:hover {
            color: $white;

            svg * {
                fill: $white;
            }
        }
    }
}
