@import "../../module.variables.scss";

.ImageGallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .image-gallery-slide img {
        object-fit: contain;
        max-height: 70vh;
    }

    .image-gallery {
        width: 100%;
    }

    .image-gallery-slide {
        background: none;
    }

    .btnClose {
        position: absolute;
        z-index: 2;
        top: 20px;
        right: 20px;
        border-radius: 50%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        cursor: pointer;
        transition: 0.25s ease;

        svg {
            height: 20px;

            * {
                fill: $dark;
            }
        }

        &:hover {
            transform-origin: center center;
            transform: scale(1.2);
        }
    }
}