@import "../../../../module.variables.scss";

.Edit {
    min-width: 400px;
    .group {
        display: flex;
        justify-content: space-between;
    }
    // .btnBan {
    //     background-color: #d99e0b;
    //     border: 1px solid #d99e0b;
    // }
}
