@import "../../module.variables.scss";

.Alert {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	max-width: 35%;
	max-height: 100%;
	overflow: auto;
	padding: 25px;
	z-index: 999;
	font-size: 15px;
 
	.Alert__Item {
		width: 100%;
		display: flex;
		align-items: center;
		min-height: 70px;
		border-radius: 3px;
		background: $white;
		box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
		margin-bottom: 25px;
		position: relative;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;

			svg {
				height: 35px;
			}
		}

		.content {
			flex: 1;
			width: 100%;
			padding: 15px 0;
			padding-right: 35px;

			.title {
				font-weight: 500;
				margin-bottom: 5px;
				color: $dark;
				font-size: 1.2em;

				&::first-letter {
					text-transform: uppercase;
				}
			}

			.message {
				color: rgba($color: $dark, $alpha: 0.5);
				word-break: break-word;
				font-size: 1.1em;
				line-height: 1.5em;
			}
		}

		.btnRemove {
			min-height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin-right: 10px;
			border-radius: 50%;
			transition: 0.3s ease;

			svg {
				height: 12px;

				* {
					fill: rgba($color: $dark, $alpha: 0.5);
				}
			}

			&:hover {
				background: rgba($color: #000000, $alpha: 0.05);
			}
		}
	}

	.Alert__Item {
		&.success {
			border-left: 5px solid $success;

			.icon svg * {
				fill: $success;
			}
		}

		&.danger {
			border-left: 5px solid $danger;

			.icon svg * {
				fill: $danger;
			}
		}

		&.warning {
			border-left: 5px solid $warning;

			.icon svg * {
				fill: $warning;
			}
		}
	}

	.Alert__Item-enter {
		opacity: 0;
	}
	.Alert__Item-enter-active {
		opacity: 1;
		transition: opacity 250ms ease-in;
	}
	.Alert__Item-exit {
		opacity: 1;
	}
	.Alert__Item-exit-active {
		opacity: 0;
		transition: opacity 500ms ease-in;
	}
}
